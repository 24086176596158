<template>
  <StateRenderer :lines="1">
    <slot v-bind="stores" />
  </StateRenderer>
</template>

<script>
import withAsyncAction from '@/mixins/withAsyncAction'
import StateRenderer from '../StateRenderer'
import services from '@/services'

export default {
  name: 'StoresFetcher',
  components: {
    StateRenderer
  },
  mixins: [
    withAsyncAction({
      key: 'stores',
      initialState: [],
      fetcher: {
        methodName: 'fetchStores',
        handler: services.stores.fetchStores
      }
    })
  ],
  created() {
    this.fetchStores()
  }
}
</script>
