<template>
  <Layout>
    <b-breadcrumb :items="breadcrumbs" />
    <StateRenderer v-bind="period">
      <PeriodForm v-model="model" :disabled="isLoading" @submit="onSubmit" />
    </StateRenderer>
  </Layout>
</template>

<script>
import { STATUS } from '@/constants'
import { mapGetters } from 'vuex'
import services from '@/services'
import withAsyncAction from '@/mixins/withAsyncAction'
import Layout from '@/views/Layout'
import PeriodForm from '@/components/Periods/PeriodForm'
import StateRenderer from '@/components/StateRenderer'
import { parsePeriod } from '@/helpers/periods'

export default {
  name: 'PeriodRegisterView',
  components: {
    Layout,
    PeriodForm,
    StateRenderer
  },
  mixins: [
    withAsyncAction({
      key: 'period',
      fetcher: {
        methodName: 'fetchPeriodById',
        handler: services.periods.fetchPeriodById
      }
    }),
    withAsyncAction({
      key: 'createPeriodState',
      fetcher: {
        methodName: 'createPeriod',
        handler: services.periods.createPeriod
      }
    }),
    withAsyncAction({
      key: 'updatePeriodState',
      fetcher: {
        methodName: 'updatePeriod',
        handler: services.periods.updatePeriod
      }
    })
  ],
  data() {
    return {
      model: {
        storeId: null,
        time: null,
        price: null,
        status: STATUS.ACTIVE.value
      }
    }
  },
  computed: {
    ...mapGetters('store', ['currentStoreId']),
    breadcrumbs: () => [
      {
        text: 'Início',
        to: { name: 'HomeIndex' }
      },
      {
        text: 'Cadastros',
        to: { name: 'RegistersIndex' }
      },
      {
        text: 'Períodos',
        to: { name: 'PeriodsIndex' }
      },
      {
        text: 'Cadastro',
        to: { name: 'PeriodRegister' },
        active: true
      }
    ],
    isLoading() {
      return this.createPeriodState.loading || this.updatePeriodState.loading
    }
  },
  async created() {
    const { id } = this.$route.params

    if (id) {
      this.model = await this.fetchPeriodById(id)
    } else {
      this.model.storeId = this.currentStoreId
    }
  },
  methods: {
    async onSubmit(period) {
      const parsedPeriod = parsePeriod(period)

      if (period.id) {
        await this.updatePeriod(parsedPeriod)
      } else {
        await this.createPeriod(parsedPeriod)
      }

      this.$root.$toast.success('Período salvo com sucesso')
      this.$router.push({ name: 'PeriodsIndex' })
    }
  }
}
</script>
