<template>
  <fieldset :disabled="disabled">
    <b-card no-body>
      <b-card-body>
        <b-form novalidate>
          <fieldset class="mb-3">
            <b-form-row>
              <b-col lg="6" md="6" sm="12">
                <b-form-group
                  label="Tempo em Minutos"
                  label-for="time"
                  :class="validationClass.time"
                >
                  <b-input-group append="minutos">
                    <b-form-input
                      id="time"
                      v-model="form.time"
                      :state="validation.time"
                    />
                  </b-input-group>
                  <b-form-invalid-feedback v-if="$v.form.time.$invalid">
                    Informe o tempo em minutos
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <b-form-group
                  label="Preço"
                  label-for="price"
                  :class="validationClass.price"
                >
                  <b-input-group prepend="R$">
                    <b-form-input
                      id="price"
                      v-model="form.price"
                      v-money="{
                        decimal: ',',
                        thousands: '.',
                        precision: 2
                      }"
                      :state="validation.price"
                    />
                  </b-input-group>
                  <b-form-invalid-feedback v-if="$v.form.price.$invalid">
                    O preço deve ser maior que zero
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>

            <StoresFetcher>
              <template #default="{ data }">
                <fieldset>
                  <div lg="6" md="6" sm="12" class="flex-1">
                    <b-form-group label="Loja" label-for="store">
                      <BaseSelect
                        v-model="form.storeId"
                        :options="parseStoreOptions(data, form.storeId)"
                        :reduce="onReduceStore"
                        :clearable="false"
                        :validation="$v.form.storeId"
                      />
                      <b-form-invalid-feedback v-if="$v.form.storeId.$invalid">
                        Informe a loja
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </fieldset>
              </template>
            </StoresFetcher>

            <StatusInput
              v-model="form.status"
              :validation="validation.status"
              :invalid="$v.form.status.$invalid"
            />
          </fieldset>
        </b-form>
      </b-card-body>
    </b-card>

    <div class="mt-3 d-flex gap-1 justify-content-end">
      <b-button
        variant="outline-secondary"
        @click="handleCancel('PeriodsIndex')"
      >
        Cancelar
      </b-button>
      <b-button variant="success" @click="handleSubmit"> Salvar </b-button>
    </div>
  </fieldset>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import withFormValidation from '@/mixins/withFormValidation'
import BaseSelect from '@/components/BaseSelect'
import StatusInput from '@/components/StatusInput'
import StoresFetcher from '@/components/Stores/StoresFetcher'
import { getValidationClass } from '@/helpers/validation'
import { isValidCurrency } from '@/helpers/validators'

export default {
  name: 'PeriodForm',
  components: {
    BaseSelect,
    StatusInput,
    StoresFetcher
  },
  mixins: [
    withFormValidation({
      fields: ['storeId', 'time', 'price', 'status']
    })
  ],
  validations: {
    form: {
      storeId: { required },
      time: { required },
      price: { isValidCurrency },
      status: { required }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    validationClass() {
      const { form } = this.$v
      const fields = ['time', 'price']

      return getValidationClass({ form, fields })
    }
  },
  methods: {
    parseStoreOptions(stores) {
      return stores.map(({ id, name }) => ({
        code: id,
        label: name
      }))
    },

    onReduceStore({ code }) {
      return code
    }
  }
}
</script>
